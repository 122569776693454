import { getModule, Module, MutationAction, VuexModule } from "vuex-module-decorators";
import store from "@/store";
import { getMaintenance, updateMaintenance } from "@/services/admin-setting.service";
import { MaintenanceInterface } from "@/types/admin-setting.type";

@Module({
  dynamic: true,
  name: "maintenanceModule",
  store,
})
class AdminSettingModule extends VuexModule {
  public maintenance: boolean = false;

  @MutationAction({ mutate: ["maintenance"] })
  public async getMaintenance() {
    return await getMaintenance();
  }

  @MutationAction({ mutate: ["maintenance"] })
  public async updateMaintenance(data: MaintenanceInterface) {
    return await updateMaintenance(data);
  }
}

export default getModule(AdminSettingModule);
