import { MaintenanceInterface } from "@/types/admin-setting.type";
import axios from "@/plugins/axios-setting";

export function getMaintenance(): Promise<MaintenanceInterface> {
  return axios.get("/api/maintenance/");
}

export function updateMaintenance(data: MaintenanceInterface): Promise<MaintenanceInterface> {
  return axios.put("/api/maintenance/", data);
}
