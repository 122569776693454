


























import { Component, Vue } from "vue-property-decorator";
import AdminSettingModule from "@/store/modules/admin-setting-module";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import Header from "@/components/Header.vue";
import WarningDialog from "@/components/utils/WarningDialog.vue";
import SaveButton from "@/components/buttons/SaveButton.vue";

@Component({
  components: {
    Header,
    WarningDialog,
    SaveButton,
  },
})
export default class AdminSetting extends Vue {
  private maintenance: boolean = false;
  private dialog: boolean = false;
  private dialogMessage: string = "";
  private startMaintenanceMessage: string =
    "System will undergo maintenance, non admin users will be forced to logout from the system.";
  private endMaintenanceMessage: string = "System will end maintenance period and return back to normal.";

  private async created() {
    await AdminSettingModule.getMaintenance();
    this.maintenance = AdminSettingModule.maintenance;
  }

  private save() {
    if (this.maintenance && !AdminSettingModule.maintenance) {
      this.dialogMessage = this.startMaintenanceMessage;
    } else if (!this.maintenance && AdminSettingModule.maintenance) {
      this.dialogMessage = this.endMaintenanceMessage;
    }
    (this.$refs.dialog as Vue & { openDialog: () => boolean }).openDialog();
  }

  private async updateMaintenance() {
    try {
      await AdminSettingModule.updateMaintenance({ maintenance: this.maintenance });
      this.maintenance = AdminSettingModule.maintenance;
      await SnackbarModule.setSnack({
        color: "success",
        message: "Update maintenance setting successfully!",
      });
    } catch ({ response }) {
      await SnackbarModule.setSnack({
        color: "error",
        message: response.data.detail,
      });
    }
    (this.$refs.dialog as Vue & { closeDialog: () => boolean }).closeDialog();
  }

  private get maintenanceChange(): boolean {
    return this.maintenance !== AdminSettingModule.maintenance;
  }
}
