

































import { Component, Prop, Vue } from "vue-property-decorator";
import SaveButton from "@/components/buttons/SaveButton.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";

@Component({
  components: {
    SaveButton,
    CloseButton,
  },
})
export default class WarningDialog extends Vue {
  private dialog: boolean = false;

  @Prop({
    type: Function,
  })
  private click!: () => void;

  @Prop({
    type: String,
    default: null,
  })
  private readonly dialogMessage!: string;

  public openDialog() {
    this.dialog = true;
  }

  public closeDialog() {
    this.dialog = false;
  }
}
